// import Vue from 'vue'

export const apiCall = { 
  state: false,
  done() {
    this.state = false
  },
  start() {
    this.state = true
  }
}

export default {
  install(Vue /* options **/) {
    // eslint-disable-next-line prefer-const
    let app = new Vue({
      data: {
        apicall: apiCall
      }
      // methods: {
      //   done(val) {
      //     console.log('VUE api call done:>> ', val)

      //     this.apicall.done(val)
      //   },
      //   start(val) {
      //     console.log('VUE api call start :>> ', val)

      //     this.apicall.start(val)
      //   }
      // }
    })
    Vue.prototype.$apiCall = app.apicall
  }
}
