import gql from 'graphql-tag'
import api from './apiClient'

import globalService from './globalService'

const story = new globalService({
  typeDefs: 'Story',
  input: 'StoryInput',
  types: {
    readAll: `_id
              state`,
    readById: `_id
              state
              lang
              template
              name
              page {
                _id
                name
                content {
                  block
                  autoTranslate
                  element {
                    field
                    type
                    value
                    groupValue {
                    _id
                      i
                      field
                      type
                      value
                    }
                  }

                }
                images {
                   field
                   value
                   _id
                    alt
                  }
                updatedAt
              }
              `,
    create: `_id
            name
            element {
              field
              value
            }
            `,
    update: `_id
            name
            element {
              field
              value
            }
            `,
    setState: `_id
              state
              `
  }
})

export default {
  ...story,
  saveStory: input =>
    api
      .send({
        query: gql`
          mutation SAVE_STORY($input: StoryInput!) {
            Story {
              saveStory(input: $input) {
                _id
              }
            }
          }
        `,
        variables: { input }
      })
      .then(result => Promise.resolve(result.data.data.Story.saveStory)),

  readByPageName: ({ storyId, name }) =>
    api
      .send({
        query: gql`
          query READ_BY_PAGENAME($storyId: ID!, $name: String!) {
            Story {
              readByPageName(storyId: $storyId, name: $name) {
                _id
                name
                content {
                  block
                  autoTranslate
                  element {
                    field
                    value
                  }
                }
                images {
                  field
                  value
                  _id
                  alt
                }
                updatedAt
              }
            }
          }
        `,
        variables: {
          storyId,
          name
        }
      })
      .then(result => Promise.resolve(result.data.data.Story.readByPageName)),

  savePage: ({ storyId, name, content }) =>
    api
      .send({
        query: gql`
          mutation SAVE_PAGE($storyId: ID!, $name: String!, $content: [ContentInput]!) {
            Story {
              savePage(storyId: $storyId, name: $name, content: $content) {
                _id
                name
                content {
                  block
                  autoTranslate
                  element {
                    field
                    value
                    type
                    groupValue {
                      i
                      field
                      type
                      value
                    }
                  }
                }
                images {
                  _id
                  field
                  value
                  alt
                }
                updatedAt
              }
            }
          }
        `,
        variables: {
          storyId,
          name,
          content
        }
      })
      .then(result => Promise.resolve(result.data.data.Story.savePage)),

  publishAll: (storyId, page, lang, triggerId, branchName, template, publishToProduction) =>
    api
      .send({
        query: gql`
          mutation PUBLISH_ALL(
            $storyId: ID!
            $page: [PageInput]!
            $lang: [String]!
            $triggerId: String!
            $branchName: String!
            $template: String!
            $publishToProduction: Boolean!
          ) {
            Story {
              publishAll(
                storyId: $storyId
                page: $page
                lang: $lang
                triggerId: $triggerId
                branchName: $branchName
                template: $template
                publishToProduction: $publishToProduction
              ) {
                _id
              }
            }
          }
        `,
        variables: {
          storyId,
          page,
          lang,
          triggerId,
          branchName,
          template,
          publishToProduction
        }
      })
      .then(result => Promise.resolve(result.data.data.Story.publishAll)),

  addLang: (storyId, lang) =>
    api
      .send({
        query: gql`
          mutation SET_LANG($storyId: ID!, $lang: String!) {
            Story {
              addLang(storyId: $storyId, lang: $lang)
            }
          }
        `,
        variables: {
          storyId,
          lang
        }
      })
      .then(result => Promise.resolve(result.data.data.Story.addLang)),

  addPage: (storyId, page) =>
    api
      .send({
        query: gql`
          mutation ADD_PAGE($storyId: ID!, $page: PageInput!) {
            Story {
              addPage(storyId: $storyId, page: $page) {
                _id
                name
                content {
                  block
                  autoTranslate
                  element {
                    field
                    value
                    type
                  }
                }
                images {
                  _id
                  field
                  value
                  alt
                }
                updatedAt
              }
            }
          }
        `,
        variables: {
          storyId,
          page
        }
      })
      .then(result => Promise.resolve(result.data.data.Story.addPage)),

  publishImage: (storyId, pageId, image, options) =>
    api
      .send({
        query: gql`
          mutation PUBLISH_IMAGE($storyId: ID!, $pageId: ID!, $image: ImageInput, $options: ImageOptions) {
            Story {
              publishImage(storyId: $storyId, pageId: $pageId, image: $image, options: $options) {
                _id
                field
                value
                alt
              }
            }
          }
        `,
        variables: { storyId, pageId, image, options }
      })
      .then(result => Promise.resolve(result.data.data.Story.publishImage)),

  deleteImage: ({ storyId, imageId, pageId, value }) =>
    api
      .send({
        query: gql`
          mutation DELETE_IMAGE($storyId: ID!, $pageId: ID!, $imageId: ID!, $value: String) {
            Story {
              deleteImage(storyId: $storyId, pageId: $pageId, imageId: $imageId, value: $value)
            }
          }
        `,
        variables: { storyId, imageId, pageId, value }
      })
      .then(result => Promise.resolve(result.data.data.Story.deleteImage)),

  saveImageGroupValue: ({ storyId, isFirst ,  parentField, block, pageName, options, imageGroupValue }) =>
    api
      .send({
        query: gql`
          mutation saveImageGroupValue(
            $isFirst: Boolean,
            $storyId: ID,
            $parentField: String,
            $block: String,
            $pageName: String,
            $options: ImageOptions,
            $imageGroupValue: imageGroupValueInput
          ) {
            Story {
              saveImageGroupValue(
                isFirst: $isFirst
                storyId: $storyId
                parentField: $parentField
                block: $block
                pageName: $pageName
                options: $options
                imageGroupValue: $imageGroupValue
              )
              {
                i
                field
                value
                type
                _id
              }
            }
          }
        `,
        variables: { storyId, isFirst, parentField, block, pageName, options, imageGroupValue }
      })
      .then(result => Promise.resolve(result.data.data.Story.saveImageGroupValue)),

  deleteImageGroupValue: ({ storyId, parentField, block, pageName, imageGroupValue }) =>
    api
      .send({
        query: gql`
          mutation deleteImageGroupValue($storyId: ID, $parentField: String, $block: String, $pageName: String, $imageGroupValue: imageGroupValueInput) {
            Story {
              deleteImageGroupValue(

                storyId: $storyId
                parentField: $parentField
                block: $block
                pageName: $pageName
                imageGroupValue: $imageGroupValue
              )
            }
          }
        `,
        variables: { storyId, parentField, block, pageName, imageGroupValue }
      })
      .then(result => Promise.resolve(result.data.data.Story.deleteImageGroupValue))
}
