import Vue from 'vue'
import Vuex from 'vuex'
import { Session } from '../plugins/session'

// Modules
import app from './app'
import appConfig from './app-config'
import verticalMenu from './vertical-menu'
import * as userStore from './modules/userStore'
import * as storyStore from './modules/storyStore'

Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    app,
    appConfig,
    verticalMenu,
    userStore,
    storyStore
  },
  actions: {
    clearAll({ commit }) {
      Session.end()
      commit('userStore/RESET_STATE')
      commit('storyStore/RESET_STATE')
      return true
    }
  },
  strict: process.env.DEV
})
