import gql from 'graphql-tag'
import api from './apiClient'

//import { runSubscription } from './subscriber'

import globalService from './globalService'

const user = new globalService({
  typeDefs: 'User',
  input: 'UserInput',
  types: {
    readAll: `_id
              email
              empresa
              fullname
              state`,
    readById: `_id
               password
               fullname
               email
               tel
               altTel
               state
               myStories {
                 _id
                 name
                 color
                 branch
                 triggerId
               }
               empresa
               role
               `,
    create: `_id
             password
             fullname
             email
             tel
             empresa
             altTel
             state
             myStories {
                _id
                name
                color
                branch
                triggerId
              }
             token`,
    update: `_id
             password
             fullname
             email
             empresa
             tel
             altTel
             state
             myStories {
              _id
              name
              color
              branch
              triggerId
             }
             token
             `,
    setState: `_id
             img
             fullname
             empresa
             email
             state`
  }
})

export default {
  ...user,

  authentication: credenciales =>
    api
      .send({
        query: gql`
          mutation login($credenciales: LoginInput!) {
            User {
              login(input: $credenciales) {
                _id
                password
                fullname
                email
                empresa
                tel
                altTel
                state
                myStories {
                  _id
                  name
                  color
                  branch
                  triggerId
                }
                token
                role
              }
            }
          }
        `,
        variables: {
          credenciales
        }
      })
      .then(result => Promise.resolve(result.data.data.User.login)),

  checkPassword: item =>
    api
      .send({
        query: gql`
          query checkPassword($item: CheckPassword!) {
            User {
              checkPassword(input: $item)
            }
          }
        `,
        variables: {
          item
        }
      })
      .then(result => Promise.resolve(result.data.data.User.checkPassword)),

  recoveryPassReq: item => {
    return api
      .send({
        query: gql`
          query recoveryPassReq($item: String!) {
            User {
              recoveryPassReq(input: $item)
            }
          }
        `,
        variables: {
          item
        }
      })
      .then(result => Promise.resolve(result.data.data.User.recoveryPassReq))
  },
  recoveryPassRes: item => {
    return api
      .send({
        query: gql`
          query recoveryPassRes($item: recoveryPassVerifyInput!) {
            User {
              recoveryPassRes(input: $item)
            }
          }
        `,
        variables: {
          item
        }
      })
      .then(result => Promise.resolve(result.data.data.User.recoveryPassRes))
  },

  recoveryPassChange: item => {
    return api
      .send({
        query: gql`
          mutation recoveryPassChange($item: recoveryPassChangeInput!) {
            User {
              recoveryPassChange(input: $item) {
                _id
                password
                fullname
                email
                tel
                altTel
                empresa
                state
                myStories {
                  _id
                  name
                  color
                  branch
                  triggerId
                }
                token
              }
            }
          }
        `,
        variables: {
          item
        }
      })
      .then(result => Promise.resolve(result.data.data.User.recoveryPassChange))
  },
  changePassword: item =>
    api
      .send({
        query: gql`
          mutation changePassword($item: CheckPassword!) {
            User {
              changePassword(input: $item)
            }
          }
        `,
        variables: {
          item
        }
      })
      .then(result => Promise.resolve(result.data.data.User.changePassword)),

  create: ({ reCaptcha, item }) =>
    api
      .send({
        query: gql`
          mutation create($reCaptcha: String, $item: UserInput!) {
            User {
              create(reCaptcha: $reCaptcha, input: $item) {
                _id
                email
                token
                state
                myStories {
                  _id
                  name
                  color
                  branch
                  triggerId
                }
                role
              }
            }
          }
        `,
        variables: {
          item,
          reCaptcha
        }
      })
      .then(result => Promise.resolve(result.data.data.User.create))
}
