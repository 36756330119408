import gql from 'graphql-tag'
import api from './apiClient'

export default {
  translate: ({ text, target, field, page, type, parentField, index }) =>
    api
      .send({
        query: gql`
          query TRANSLATE($text: String!, $target: String!) {
            Translate {
              translate(text: $text, target: $target)
            }
          }
        `,
        variables: {
          text,
          target
        }
      })
      .then(result => Promise.resolve({ textTranslated: result.data.data.Translate.translate, target, field, page, type, parentField, index }))
}
