export const Session = {
  user: {},
  story: {},
  token: null,
  state: false,
  filter: null,
  needsAuth: false,
  fingerPrint: null,
  getFingerPrint() {
    if (!this.fingerPrint) {
      // FIXME: crear el fingerprint dinamico
      this.fingerPrint = 'STORIES2021'
    }
    return this.fingerPrint
  },
  end() {
    this.user = {}

    this.needsAuth = false
    this.state = false
    this.token = null
    this.fingerPrint = null
    localStorage.removeItem(this.getFingerPrint())
    localStorage.removeItem('filter')
  },
  setAuth(val) {
    this.needsAuth = val
  },

  set(val) {
    const { _id, email, fullname, role, state, myStories, empresa, tel, token } = val
    if (token) this.setToken(token)
    this.setAuth(false)
    this.user = { _id, email, fullname, role, state, myStories, empresa, tel }

    this.story = myStories[0]

    const idSelected = localStorage.getItem('idSelected')

    if (idSelected) {
      myStories.find(s => s._id.toString() === idSelected.toString())
        ? (this.story = myStories.find(s => s._id.toString() === idSelected.toString()))
        : (this.story = myStories[0])
    } else this.story = myStories[0]
  },
  setStoryId(story) {
    this.story = story
  },
  setToken(t) {
    localStorage.setItem(this.getFingerPrint(), JSON.stringify(t))
    this.token = JSON.stringify(t)
    this.isValid()
  },
  getToken() {
    if (!this.token) {
      const eLocal = localStorage.getItem(this.getFingerPrint()) || null
      if (eLocal) this.token = eLocal
    }
    return JSON.parse(this.token)
  },
  isValid() {
    let currentState = false
    const t = this.getToken()
    if (t) {
      const { exp } = JSON.parse(Buffer.from(t.split('.')[1], 'base64').toString('ascii'))
      const currentTime = new Date().getTime() / 1000
      if (parseInt(currentTime) <= parseInt(exp)) currentState = true
    }
    this.state = currentState
    // if (!currentState) this.end()
    return this.state
  }
}

export default {
  install(Vue /* options */) {
    // eslint-disable-next-line prefer-const
    let app = new Vue({
      data: {
        session: Session
      }
    })
    Vue.prototype.$session = app.session
  }
}
