import Vue from 'vue'
import { ToastPlugin, ModalPlugin } from 'bootstrap-vue'
import VueCompositionAPI from '@vue/composition-api'
import '../src/plugins/registerServiceWorker'

import router from './router'
import store from './store'
import App from './App.vue'

// Global Components.
import './global-components'

// 3rd party plugins
import '@/libs/portal-vue'
import '@/libs/toastification'
import '@/libs/sweet-alerts'

import session from './plugins/session'
Vue.use(session)

import apiCall from './plugins/apiCall'
Vue.use(apiCall)

import noti from './plugins/noti'
Vue.use(noti)

import connectionCheck from './plugins/connectionCheck'
Vue.use(connectionCheck)

// BSV Plugin Registration
Vue.use(ToastPlugin)
Vue.use(ModalPlugin)

// Composition API
Vue.use(VueCompositionAPI)

// import core styles
require('@core/scss/core.scss')

// import assets styles
require('@/assets/scss/style.scss')

import 'quill/dist/quill.core.css'
import 'quill/dist/quill.snow.css'

import VueQuillEditor from 'vue-quill-editor'
Vue.use(VueQuillEditor, {
  modules: {
    toolbar: [
      ['bold', 'italic', 'underline', 'strike', 'link'],
      ['blockquote', 'code-block'],
      [{ 'header': 1 }, { 'header': 2 }],
      [{ 'list': 'ordered' }, { 'list': 'bullet' }],
      [{ 'indent': '-1' }, { 'indent': '+1' }],
      [{ 'size': ['small', false, 'large', 'huge'] }],
      [{ 'header': [1, 2, 3, 4, 5, 6, false] }],
      [{ 'color': [] }, { 'background': [] }],
      // [{ 'font': [] }],
      [{ 'align': ['center'] }],
      [{ 'align': ['justify'] }],
      [{ 'align': ['right'] }],
      [{ 'align': [''] }],
      ['clean']
    ],
    syntax: {
      highlight: text => hljs.highlightAuto(text).value
    }
  }
})

Vue.config.productionTip = false

// google tags - Analytics
import VueGtag from 'vue-gtag'

Vue.use(
  VueGtag,
  {
    config: { id: 'G-YSD8WSSPKG' },
    appName: 'StoriesAdmin',
    pageTrackerScreenviewEnabled: true
  },
  router
)

new Vue({
  created() {
    if (this.$session.isValid()) {
      const { _id, email, fullname, role, state, myStories, branch, triggerId, empresa, tel } = JSON.parse(
        Buffer.from(this.$session.getToken().split('.')[1], 'base64').toString('ascii')
      )

      this.$session.set({ _id, email, fullname, role, state, myStories, branch, triggerId, empresa, tel })
      this.$store.commit('userStore/SAVED')
    }
  },
  router,
  store,
  render: h => h(App)
}).$mount('#app')
