import globalStore from './globalStore'
import service from '../../services/user'
import { Session } from '../../plugins/session'

const user = new globalStore({
  user: {},

  list: [],
  saved: null,
  // módulo del servicio a importar para conexión con api GQL
  serviceModule: 'user'
})

export const namespaced = true
export const state = { ...user.state }
export const mutations = {
  ...user.mutations,
  SAVED: state => (state.saved = true)
}
export const actions = {
  ...user.actions,
  ...{
    recoveryPassChange: (_, item) => {
      return service.recoveryPassChange(item).then(({ _id, email, empresa, fullname, state, token, myStories, filter }) => {
        if (filter) Session.setFilter(filter)
        return Session.set({ _id, email, state, fullname, empresa, myStories, token })
      })
    },
    recoveryPassReq: (_, input) => {
      return service.recoveryPassReq(input).then(res => res)
    },
    recoveryPassRes: (_, item) => {
      item.code = parseInt(item.code)

      return service.recoveryPassRes(item).then(res => res)
    },

    create: (_, { reCaptcha, item }) =>
      service.create({ reCaptcha, item }).then(({ _id, tel, role, empresa, email, state, myStories, token }) => {
        Session.set({ _id, tel, empresa, email, role, myStories, state, token })
      }),
    update: ({ commit }, item) =>
      service.update({ ...item }).then(response => {
        commit('SAVED')
        return Session.set(response)
      }),
    authentication: ({ commit }, credentials) =>
      service.authentication(credentials).then(({ _id, email, role, fullname, tel, empresa, state, myStories, token }) => {
        console.log('role :>> ', role)
        commit('SAVED')
        return Session.set({ _id, email, state, fullname, empresa, role, tel, myStories, token })
      }),
    checkPassword: ({ state }, oldPassword) => service.checkPassword({ _id: state.user._id, oldPassword }),
    changePassword: ({ state }, item) => service.changePassword({ _id: state.user._id, ...item })
  }
}
export const getters = {
  ...user.getters
}
