import Vue from 'vue'
import VueRouter from 'vue-router'
import { Session } from '../plugins/session'

const routes = require('./routes')

Vue.use(VueRouter)

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  scrollBehavior() {
    return { x: 0, y: 0 }
  },
  routes
})

router.afterEach(() => {
  // Remove initial loading
  const appLoading = document.getElementById('loading-bg')
  if (appLoading) {
    appLoading.style.display = 'none'
  }
})

router.beforeEach((to, from, next) => {
  if (to.matched.some(record => record.meta.requiresAuth) && !Session.isValid()) {
    Session.setAuth(true)
    return next('/login')
  }

  return next()
})

export default router
