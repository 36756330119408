// import Vue from 'vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  install(Vue /* options **/) {
    // eslint-disable-next-line prefer-const
    let app = new Vue({
      methods: {
        success(text, title = '') {
          this.$toast(
            {
              component: ToastificationContent,
              props: {
                title: title || 'Success!',
                icon: 'SendIcon',
                text,
                variant: 'success'
              }
            },
            { position: 'bottom-right' }
          )
        },
        info(text, title) {
          this.$toast(
            {
              component: ToastificationContent,
              props: {
                title: title || 'Info',
                icon: 'MessageSquareIcon',
                text,
                variant: 'info'
              }
            },
            { position: 'bottom-right' }
          )
        },
        warn(text, title) {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: title || 'Warning',
              text,
              icon: 'MinusCircleIcon',
              variant: 'warning'
            }
          })
        },
        error(text, title) {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: title || 'Error',
              icon: 'XSquareIcon',
              text,
              variant: 'danger'
            }
          })
        }
      }
    })
    Vue.prototype.$noti = app
  }
}
