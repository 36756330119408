
module.exports = [
  {
    path: '/home',
    name: 'home',
    component: () => import(/* webpackChunkName: "home" */ '@/views/pages/Home.vue'),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/userProfile',
    name: 'userProfile',
    component: () => import(/* webpackChunkName: "userProfile" */ '@/views/pages/UserProfile.vue'),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/chat',
    name: 'chat',
    component: () => import(/* webpackChunkName: "chat" */  '@/views/pages/Chat.vue'),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/login',
    name: 'login',
    component: () => import(/* webpackChunkName: "login" */ '@/views/pages/Login.vue'),
    meta: {
      layout: 'full'
    }
  },
  {
    path: '/register',
    name: 'register',
    component: () => import(/* webpackChunkName: "register" */ '@/views/pages/Register.vue'),
    meta: {
      layout: 'full'
    }
  },
  {
    path: '/error-404',
    name: 'error-404',
    component: () => import('@/views/error/Error404.vue'),
    meta: {
      layout: 'full'
    }
  },
  {
    path: '/recoveryPass',
    name: 'recoveryPass',
    component: () => import(/* webpackChunkName: "RecoveryPass" */ '@/views/pages/RecoveryPass.vue'),
    meta: {
      layout: 'full'
    }
  },
  {
    path: '/resetPassword',
    name: 'resetPassword',
    component: () => import(/* webpackChunkName: "ResetPassword" */ '@/views/pages/ResetPassword.vue'),
    meta: {
      layout: 'full'
    }
  },
  {
    path: '*',
    redirect: '/home'
  }
]
