/* eslint-disable no-console */
// import swal from 'sweetalert2'
// import 'sweetalert2/dist/sweetalert2.css'
import { register } from 'register-service-worker'

if (process.env.NODE_ENV === 'production') {
  register(`${process.env.BASE_URL}service-worker.js`, {
    registrationOptions: {
      scope: process.env.BASE_URL
    },
    ready() {
      console.log(
        // eslint-disable-next-line no-useless-concat
        'App is being served from cache by a service worker.\n' + 'For more details, visit https://goo.gl/AFskqB'
      )
    },
    registered() {
      console.log('Service worker has been registered.')
    },
    cached() {
      console.log('Content has been cached for offline use.')
    },
    updatefound() {
      console.log('New content is downloading.')
    },
    updated() {
      console.log('New content is available; please refresh.')
      // swal.fire({
      //   title: 'Hola! mejoramos el servicio!',
      //   text: 'Para una mejor experiencia, refrescá tu browser.',
      //   confirmButtonColor: '#3085d6',
      //   cancelButtonColor: 'text-info',
      //   confirmButtonText: 'Listo'
      //   // allowOutsideClick: false
      // })
    },
    offline() {
      console.log('No internet connection found. App is running in offline mode.')
    },
    error(error) {
      console.error('Error during service worker registration:', error)
    }
  })
}
