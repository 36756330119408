import globalStore from './globalStore'
import service from '@/services/story'
import serviceTranslate from '@/services/translate/translate'
import { Session } from '../../plugins/session'
import { paginateArray } from '@/util/util'

const story = new globalStore({
  story: {
    _id: null,
    state: true,
    page: [
      {
        images: []
      }
    ],
    template: ''
  },

  bkpList: localStorage.getItem('storybackup') ? JSON.parse(localStorage.getItem('storybackup')) : [],

  // módulo del servicio a importar para conexión con api GQL
  serviceModule: 'story'
})

export const namespaced = true
export const state = { ...story.state }
export const mutations = {
  ...story.mutations,
  // UPDATE_GV_IMAGES: (state, { parentField, block, pageName, imageGroupValue, img }) => {

  // },
  DELETE_GV_IMAGES: (state, { parentField, block, pageName, imageGroupValue }) => {
    // const pagina = state.story.page.find(e => e.name === pageName)
    // console.log('imageGroupValue :>> ', imageGroupValue)
    if (block === 'es') {
      state.story.page.forEach(p => {
        if (p.name === pageName) {
          p.content.forEach(c => {
            c.element.forEach(e => {
              if (e.field === parentField) {
                e.groupValue.forEach(gv => {
                  gv.forEach((del, index) => {
                    if (del.value === imageGroupValue.value) {
                      gv.splice(index, 1)
                    }
                  })
                })
              }
            })
          })
        }
      })
    } else {
      state.story.page.forEach(p => {
        if (p.name === pageName) {
          p.content.forEach(c => {
            if (c.block === block) {
              c.element.forEach(e => {
                if (e.field === parentField) {
                  e.groupValue.forEach(gv => {
                    gv.forEach((del, index) => {
                      if (del.value === imageGroupValue.value) {
                        gv.splice(index, 1)
                      }
                    })
                  })
                }
              })
            }
          })
        }
      })
    }
  },
  UPDATE_IMAGES: (state, { res, pageId }) => {
    const newImage = state.story?.page?.find(e => {
      return e._id.toString() === pageId.toString()
    })
    const im = newImage.images?.find(i => i._id?.toString() === res._id.toString())
    if (!im) {
      newImage.images.push(res)
    }
  },
  DELETE_IMAGE: (state, { imageId, pageId }) => {
    const delImage = state.story.page.find(e => e._id.toString() === pageId.toString())
    delImage.images = delImage.images.filter(e => e._id.toString() !== imageId.toString())
  },
  SET_PAGE_LANG: (state, obj) => {
    state.story.page = obj.page
    state.story.lang = obj.lang
  },
  SET_PAGE: (state, obj) => {
    const newPage = state.story?.page?.find(e => e._id === obj._id)
    if (!newPage) state.story.page = [...(state.story.page || []), obj]
    else Object.keys(newPage).forEach(i => (newPage[i] = obj[i]))
  },

  // SET_BLOG: (state, obj) => {
  //   state.story = { ...state.story, blog: obj }
  // },
  // NEW_POST: (state, obj) => {
  //   let post = state.story.blog.find(e => e._id.toString() === obj._id.toString())

  //   if (post) {
  //     post = obj
  //   } else {
  //     state.story.blog.push(obj)
  //   }
  // },
  // DELETE_POST: (state, postId) => (state.story = { ...state.story, blog: state.story.blog.filter(e => e._id !== postId) }),

  ADD_LANG: (state, obj) => {
    state.story.lang.push(obj)
    // const newLang = state.story.lang.find(e => e === obj)
    // if (!newLang) state.story.lang = [...(state.story.lang || []), obj]
    // else Object.keys(newLang).forEach(i => (newLang[i] = obj[i]))
  },
  SET_BACKUP: (state, objs) => (state.bkpList = objs)
}
export const actions = {
  ...story.actions,

  saveStory(_, input) {
    if (!input._id) delete input._id

    return service.saveStory(input)
  },
  readByPageName({ commit }, { storyId, name }) {
    return service.readByPageName({ storyId, name }).then(response => {
      const hasPage = state.story?.page?.find(e => e.name === name)
      if (hasPage) return hasPage

      return commit('SET_PAGE', response)
    })
  },
  // readBlogById({ commit }, storyId) {
  //   return service.readBlogById(storyId).then(response => {
  //     return commit('SET_BLOG', response)
  //   })
  // },
  addLang: async ({ commit, dispatch, state }, lang) => {
    dispatch('setBackup', { story: state.story, comentario: `Antes de Nuevo Lenguaje ${lang} (auto)` })

    // actualiza todas las paginas con el nuevo idioma
    const newPages = state.story.page.map(page => ({
      ...page,
      updatedAt: Date.now().toString(),
      content: [
        ...page.content,
        {
          block: lang,
          autoTranslate: true,
          element:
            page.content
              .find(cont => cont.block === 'es')
              ?.element.map(el => ({
                ...el,
                value: '',
                groupValue: el.groupValue.map(group => group.map(gval => ({ ...gval, value: '' })))
              })) || []
        }
      ]
    }))

    // guarda los cambios en las paginas
    dispatch('publishAll', {
      story: { lang: [...state.story.lang, lang], page: newPages, template: state.story.template },
      publishToProduction: false
    })
  },
  setBackup({ commit, state }, { story, comentario }) {
    const newBackup = {
      date: Date.now().toString(),
      comentario,
      story
    }
    const [a, b, c, d, e, f, g, h, i, j] = state.bkpList
    const last = []
    if (a) last.push(a)
    if (b) last.push(b)
    if (c) last.push(c)
    if (d) last.push(d)
    if (e) last.push(e)
    if (f) last.push(f)
    if (g) last.push(g)
    if (h) last.push(h)
    if (i) last.push(i)
    if (j) last.push(j)

    let backup = null
    if (last.length !== 0) backup = [newBackup, ...last]
    else backup = [newBackup]

    try {
      localStorage.setItem('storybackup', JSON.stringify(backup))
      commit('SET_BACKUP', backup)
    } catch (error) {
      console.warn('Error al crear el respaldo.', error)
    }
  },
  addPage: ({ commit, dispatch, state }, page) =>
    service.addPage(state.story._id, page).then(res => (res ? commit('SET_PAGE', res) && dispatch : res)),
  savePage: ({ commit }, { storyId, page }) => {
    console.log('page :>> ', page)
    return service.savePage({ storyId, name: page.name, content: page.content }).then(res => {
      commit('SET_PAGE', res)
      commit('SAVED')
    })
  },
  publishImage: ({ commit }, { storyId, pageId, image, options }) => {
    return service.publishImage(storyId, pageId, image, options).then(res => {
      if (res) {
        if (!image._id) {
          commit('UPDATE_IMAGES', { res, pageId })
        }
        return res
      }
    })
  },
  deleteImage: ({ commit }, { storyId, imageId, pageId, value }) => {
    return service.deleteImage({ storyId, imageId, pageId, value }).then(res => {
      if (res) {
        commit('DELETE_IMAGE', { imageId, pageId })
      }
      return res
    })
  },
  publishAll: ({ commit, dispatch, state }, { story, publishToProduction }) => {
    dispatch('setBackup', { story: state.story, comentario: 'Antes de publicar (auto)' })

    return service
      .publishAll(state.story._id, story.page, story.lang, Session.story.triggerId, Session.story.branch, story.template, publishToProduction)
      .then(() => {
        commit('SET_PAGE_LANG', story)
        commit('SAVED')
      })
  },
  translate: async ({ commit, dispatch, state }, { page, selectedField = null }) => {
    dispatch('setBackup', { story: state.story, comentario: `Antes de traducción ${page.name} (auto)` })

    const apiTranslate = []

    const textEs = {
      name: page.name,
      element: page.content.find(cont => cont.block === 'es').element
    }
    page.content.forEach(({ block, autoTranslate, element }) => {
      if (autoTranslate && block !== 'es' && element.length !== 0)
        element.forEach(el => {
          if (el.type === 'INPUT' || el.type === 'EDITOR') {
            if (selectedField && selectedField.field !== el.field) return
            const text = textEs.element.find(txt => txt.field === el.field).value
            if (!isNaN(text)) return

            apiTranslate.push(
              serviceTranslate.translate({
                text,
                target: block,
                field: el.field,
                page: page.name,
                type: el.type
              })
            )
          } else if (el.type === 'GROUP')
            el.groupValue.forEach(group => {
              group.forEach(gval => {
                if (gval.type === 'IMAGES') return
                if (selectedField && selectedField.field !== gval.field) return

                const text = textEs.element.reduce((txt, item) => {
                  if (item.type === 'GROUP') {
                    const groupValue = item.groupValue.reduce(
                      (vv, txtgroup) => txtgroup.find(vvgval => vvgval.field === gval.field && vvgval.i === gval.i) || vv,

                      {}
                    )

                    if (groupValue.value) txt = groupValue.value
                  }
                  return txt
                }, 'error interno en la traducción, comuniquese con el administrador (a) de cuenta.')
                if (!isNaN(text)) return

                apiTranslate.push(
                  serviceTranslate.translate({
                    text,
                    target: block,
                    field: gval.field,
                    page: page.name,
                    type: el.type,
                    parentField: el.field,
                    index: gval.i
                  })
                )
              })
            })
        })
    })

    return Promise.all(apiTranslate).then(response => {
      response.forEach(item => {
        const mypage = state.story.page.find(e => e.name === item.page)
        const content = mypage.content.find(cont => cont.block === item.target)
        const element = content.element.find(el => el.field === (item.type === 'GROUP' ? item.parentField : item.field))
        if (item.type === 'INPUT' || item.type === 'EDITOR') element.value = item.textTranslated
        else if (item.type === 'GROUP') {
          const groupElement = element.groupValue.reduce(
            (acc, group) => group.find(gval => gval.field === item.field && gval.i === item.index) || acc,
            {}
          )

          groupElement.value = item.textTranslated
        }
      })
    })
  },
  copy: async ({ commit, dispatch, state }, { page, selectedField = null }) => {
    dispatch('setBackup', { story: state.story, comentario: `Antes de traducción ${page.name} (auto)` })

    const textEs = {
      name: page.name,
      element: page.content.find(cont => cont.block === 'es').element
    }
    page.content.forEach(({ block, autoTranslate, element }) => {
      if (autoTranslate && block !== 'es' && element.length !== 0)
        element.forEach(el => {
          if (el.type === 'INPUT' || el.type === 'EDITOR') {
            if (selectedField && selectedField.field !== el.field) return
            const text = textEs.element.find(txt => txt.field === el.field).value
            if (!isNaN(text)) return

            el.value = text
          } else if (el.type === 'GROUP')
            el.groupValue.forEach(group => {
              group.forEach(gval => {
                if (gval.type === 'IMAGES') return
                if (selectedField && selectedField.field !== gval.field) return

                const text = textEs.element.reduce((txt, item) => {
                  if (item.type === 'GROUP') {
                    const groupValue = item.groupValue.reduce(
                      (vv, txtgroup) => txtgroup.find(vvgval => vvgval.field === gval.field && vvgval.i === gval.i) || vv,

                      {}
                    )
                    if (groupValue.value) txt = groupValue.value
                  }
                  return txt
                }, 'error interno en la copia, comuniquese con el administrador (a) de cuenta.')

                gval.value = text
              })
            })
        })
    })

    return true
  },

  saveImageGroupValue: async (/* { commit }, */ _,{ storyId, isFirst, parentField, block, pageName, options, imageGroupValue }) => {
    console.log('STORE',isFirst);
     return service.saveImageGroupValue({ storyId, isFirst, parentField, block, pageName, options, imageGroupValue }).then(res => {
      console.log('res :>> ', res);
      return res
    })
      // if (res) {
      //    commit('UPDATE_GV_IMAGES', { parentField, block, pageName, imageGroupValue, img: res })
      // }
  },

  deleteImageGroupValue: ({ commit }, { storyId, parentField, block, pageName, imageGroupValue }) => {
    return service.deleteImageGroupValue({ storyId, parentField, block, pageName, imageGroupValue })
    .then(res => {
      if (res) {
    commit('DELETE_GV_IMAGES', { parentField, block, pageName, imageGroupValue })
        return res
      }
    })
  }
}

export const getters = {
  ...story.getters,
  getPage: state => name => state.story.page.find(e => e.name === name),
  // getBlog: state => state.story.blog,
  getLang: state => name => state.story.lang
}
